const dateRegExp = /^\d{2}\/\d{2}\/\d{4}$/

function validarData (input) {
  if (!dateRegExp.test(input)) {
    return false
  }
  const d = input.split('/')
  const toComp = new Date(d[2], d[1] - 1, d[0]).toJSON().slice(0, 10)

  return toComp === `${d[2]}-${d[1]}-${d[0]}`
}

export const validDate = (data) => validarData(data)
