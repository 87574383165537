<template>
  <div :class="classInput">
    <div class="form-group">
      <label class="label" :class="{'required': isRequired}">{{ label }}</label>
      <input
        v-mask="mask"
        :value="value"
        type="text"
        inputmode="numeric"
        class="form-control"
        :class="{'is-invalid': isInvalid}"
        @blur="() => validate()"
        @input="inputHandle($event)"
      >
      <div
        v-if="isInvalid"
        class="invalid-feedback"
      >
        <span v-if="isInvalid">Data obrigatória.</span>
      </div>
    </div>
  </div>
</template>

<script>
import { validDate } from './validate/validDate'
import { VueMaskDirective } from 'v-mask'

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    typeInput: {
      type: String
    },
    value: {
      type: String
    },
    classInput: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: true
    }
  },
  directives: {
    mask: VueMaskDirective
  },
  data () {
    return {
      mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      isInvalid: false,
      hasTouched: false
    }
  },
  methods: {
    inputHandle (e) {
      this.hasTouched = true
      this.$emit('input', e.target.value)
    },
    validate () {
      this.isInvalid = (!validDate(this.value) && this.hasTouched) || (this.hasTouched && this.value === null) || (this.isRequired && !this.value)
    },
    isValid () {
      this.validate()
      return !this.isInvalid
    }
  }
}
</script>
